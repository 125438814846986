import React from "react";
import blockchain from '../img/graphics/Blockchain.png'

const Section4: React.Component<Props> = ({ children }) => {
  return (
    <div className=""
      style = {{
        minHeight:"70vh",
        margin: "0vw",
        backgroundColor: "#0b0b0b",
        paddingLeft: "4vw",
        paddingRight: "4vw",
    }}
    >
      <div className="row w-100 p0 m0"
      style = {{
        fontFamily: "PosteramaRegular",
        letterSpacing: "0.05rem",
        fontSize: "2.8vw",
        color: "#ffffff",
        paddingTop: "11.7vh",
        opacity: "1",
        width: "100vh",
        margin: "0",
      }}
      >
        <div className="col-6 pl-0 pt-0 pr-5 pb-5 m0">
        <img className="" src={blockchain} alt="Blockchain" width="100%"/>  
        </div>
        <div className="col-6 p-0 pt-4 text-left">
          <div className="pb-5">
            Powered by &nbsp; 
            <span
            style = {{
              color:"#fa2aa0",
              textDecoration: "",
            }}
            >Blockchain</span>.
          </div>
          <div className="pb-5">
            Backed by&nbsp; 
            <span
            style = {{
              color:"#1afba9",
              textDecoration: "",
            }}
            >USD</span>&nbsp;stable coins.
          </div>
          <div className="pb-5">
            MULTICHAIN NATIVE <span className="pink">$ISS</span> TOKEN VIA <span className="green ">LAYERZERO</span> TECH.
          </div>
          
          
        </div>
      </div>
      

      
  </div>
  );
};

export default Section4;