import React from "react";
import coreTeam from '../img/graphics/Coreteam.png'

const Section6: React.Component<Props> = ({ children }) => {
  return (
    <div className=""
      style = {{
        minHeight:"70vh",
        margin: "0vw",
        backgroundColor: "#0b0b0b",
        paddingLeft: "4vw",
        paddingRight: "4vw",
    }}
    >
      <div className="row w-100 p0 m0"
      style = {{
        fontFamily: "PosteramaRegular",
        letterSpacing: "0.05rem",
        lineHeight: "6vh",
        fontSize: "2.8vw",
        color: "#ffffff",
        paddingTop: "11.7vh",
        opacity: "1",
        width: "100vh",
        margin: "0",
      }}
      >

        <div className="col-6 p-0 pt-4 text-left">
          <div>
          ISSUAA <span className="pink ">Community</span> DAO  <span className="green ">Governance</span>       
          </div>
          
          <div className=""
          style = {{
            fontFamily: "OpenSansLight",
            letterSpacing: "0rem",
            lineHeight: "1.5rem",
            fontSize: "1.5vw",
            color: "#ffffff",
            paddingTop: "4vh",
            opacity: "1",
            margin: "0",
          }}>
            The ISSUAA protocol has been set up as a DAO (Decentralized Autonomous Organization) from day one by the team of early core contributors to the protocol, who comprise vast experiences and competencies in crypto and blockchain technology, capital markets, global asset management and derivatives.<p />
            The team is convinced, that laying the ISSUAA protocol development  and exclusive governance via voting procedures into the hands of the community of owners of the ISSUAA protocol token is the right  way to attract and retain the necessary competencies and participation for the future development and growth of ISSUAA as a truly decentralized finance protocol.
          </div>
        </div>
        <div className="col-6 pl-0 pt-0 pr-5 pb-5 m0">
          <img className="" src={coreTeam} alt="Team" width="100%"/>  
        </div>
      </div>
      

      
  </div>
  );
};

export default Section6;