import React from "react";
import discordIcon from '../img/discord-icon-white.png'
import globe from '../img/icons/Globe.png'
import blog from '../img/icons/Blog.png'
import docs from '../img/icons/Goverance.png'
import bull from '../img/graphics/Bull.png'
import Slide from '@successtar/react-reveal/Slide';
import MobileButton from './mobileButton';
import MobileNavbar from './mobileNavbar';

const MobileSection1: React.Component<Props> = ({ children }) => {
  return (
    <div className="row"
      style = {{
        minHeight:"100vh",
        margin: "0vw"
    }}
    >
      <div className="image">
        
        <div className="layerMobile ">
          <MobileNavbar></MobileNavbar>
          <div className=""
              style = {{
              width:"100vw",
              height: "40vh",
              transform: "scaleX(-1)",
              paddingTop: "0vh",
              textAlign:"center",
              
            }}
          >
            <Slide right><img src={bull} alt="Bull" height="100%"/></Slide>
          </div>
          <div className="" style = {{
            paddingLeft:"20vw",
            paddingRight:"20vw",
            paddingBottom: "10vh"  
          }}
        >
            <div style={{
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.4rem",
              fontSize: "10vw",
              fontWeight: "bold",
              color: "#fa2aa0",
              paddingTop: "0vh",
              opacity: "1",
            }}>
              ISSUAA
            </div>
            <div style={{
              fontSize: "4.5vw",
              color: "white",
              paddingRight: "0vw"
            }}
            className = "claimText"
            >
                Novel DeFi protocol for Derivatives of virtually any Crypto and Real World Asset - on Blockchain
            </div>
            <a href="https://app.issuaa.org" rel="noopener">
              <MobileButton>
                  CHECK OUR WEBAPP
                  &nbsp;
                  <img src={globe} alt="Globe" width="30em"/>
              </MobileButton>
            </a>
            <a href="https://discord.gg/ttu8vEQM6G" rel="noopener">
              <MobileButton>
                  JOIN OUR DISCORD
                  &nbsp;
                  <img src={discordIcon} alt="Discord" width="30em"/>
              </MobileButton>
            </a>
            <a href="https://medium.com/@issuaa" rel="noopener">
              <MobileButton>
                  VISIT OUR BLOG
                  &nbsp;
                  <img src={blog} alt="Blog" width="30em"/>
              </MobileButton>
            </a>
            <a href="https://issuaa.gitbook.io/" rel="noopener">
              <MobileButton>
                  VISIT OUR DOCS
                  &nbsp;
                  <img src={docs} alt="Docs" width="30em"/>
              </MobileButton>
            </a>
          </div>
        </div>

      </div>

      
  </div>
  );
};

export default MobileSection1;