import React, { Component } from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Footer from './Footer';




class Mainpage extends Component {
    state = { 
    };

    

    render() { 
        return ( 
            <div className="p0 m0">
                <Section1></Section1>
                <Section2></Section2>
                <Section3></Section3>
                <Section4></Section4>
                <Section5></Section5>
                <Section6></Section6>
                <Footer />
            </div>
                
             


            
         );
    }
}
 
export default Mainpage;