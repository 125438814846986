import React, {useState} from 'react';


interface Props {
  border: string;
  color: string;
  children?: React.ReactNode;
  height: string;
  onClick: () => void;
  radius: string;
  width: string;
}

const Stepper: React.Component<Props> = ({
  children,
  onClick,
}) => {
  const [activePage, setActivePage] = useState(1);
  
  function changeView(page) {
    setActivePage(page)
  };

  return (
    <div className=''
      style = {{
        width: "55vw",
      }}
    >
      <div className="row ml-0  mr-0 mt-5 ">
        <div className=" ">
          {activePage === 1?
          <div className="stepper"
            style={{
              width:"30px",
              height:"30px",
              margin:"0 auto",
              marginLeft: "3vw",
              border:"solid",
              borderRadius: "50%",
              color:"#Fa3aa0",
              backgroundColor: "#fa2aa0",
            }}
            onClick={() =>changeView(1)}
            >
          </div>
          :
          <div className="stepper"
            style={{
              width:"30px",
              height:"30px",
              margin:"0 auto",
              marginLeft: "3vw",
              border:"solid",
              borderRadius: "50%",
              color:"#FFFFFF",
              backgroundColor: "#FFFFFF",
            }}
            onClick={() =>changeView(1)}
            >
          </div>
          }
            
        </div>
        <div className="col-3  m0 p0"
          style={{padding: "0px",}}
        >
          <div className=""
            style={{
              width:"100%",
              height:"4px",
              marginTop:"13px",
              marginLeft: "0px",
              paddingLeft: "0px",
              border:"solid",
              borderRadius: "0%",
              color:"#FFFFFF",
              backgroundColor: "#FFFFFF",
            }}>
            </div>
        </div>
        <div className="">
          {activePage === 2 ?
            <div className="stepper"
              style={{
                width:"30px",
                height:"30px",
                margin:"0 auto",
                border:"solid",
                borderRadius: "50%",
                color:"#fa2aa0",
                backgroundColor: "#fa2aa0",
                }}
              onClick={() =>changeView(2)}
            >
            </div>
            :
            <div className="stepper"
              style={{
                width:"30px",
                height:"30px",
                margin:"0 auto",
                border:"solid",
                borderRadius: "50%",
                color:"#FFFFFF",
                backgroundColor: "#FFFFFF",
                }}
              onClick={() =>changeView(2)}
            >
            </div>
          }
        </div>
        <div className="col-3  m0 p0"
          style={{padding: "0px",}}
        >
          <div className=""
            style={{
              width:"100%",
              height:"4px",
              marginTop:"13px",
              marginLeft: "0px",
              paddingLeft: "0px",
              border:"solid",
              borderRadius: "0%",
              color:"#FFFFFF",
              backgroundColor: "#FFFFFF",
            }}>
            </div>
        </div>
        <div className="">
          {activePage === 3 ?
            <div className="stepper"
              style={{
                width:"30px",
                height:"30px",
                margin:"0 auto",
                border:"solid",
                borderRadius: "50%",
                color:"#fa2aa0",
                backgroundColor: "#fa2aa0",
              }}
              onClick={() =>changeView(3)}
              >
            </div>
            :
            <div className="stepper"
              style={{
                width:"30px",
                height:"30px",
                margin:"0 auto",
                border:"solid",
                borderRadius: "50%",
                color:"#FFFFFF",
                backgroundColor: "#FFFFFF",
              }}
              onClick={() =>changeView(3)}
              >
            </div>
          }
        </div>
        <div className="col-3  m0 p0"
          style={{padding: "0px",}}
        >
          <div className=""
            style={{
              width:"100%",
              height:"4px",
              marginTop:"13px",
              marginLeft: "0px",
              paddingLeft: "0px",
              border:"solid",
              borderRadius: "0%",
              color:"#FFFFFF",
              backgroundColor: "#FFFFFF",
            }}>
            </div>
        </div>
        <div className="">
          {activePage === 4 ?
            <div className="stepper"
              style={{
                width:"30px",
                height:"30px",
                margin:"0 auto",
                border:"solid",
                borderRadius: "50%",
                color:"#fa2aa0",
                backgroundColor: "#fa2aa0",
              }}
              onClick={() =>changeView(4)}
              >
            </div>
            :
            <div className="stepper"
              style={{
                width:"30px",
                height:"30px",
                margin:"0 auto",
                border:"solid",
                borderRadius: "50%",
                color:"#FFFFFF",
                backgroundColor: "#FFFFFF",
              }}
              onClick={() =>changeView(4)}
              >
            </div>
          }
        </div>

      </div>
      <div className="row"
        style = {{
          fontFamily: "OpenSansBold",
          letterSpacing: "0rem",
          fontSize: "2vw",
          color: "#ffffff",
          paddingTop: "10vh",
          opacity: "1",
          width: "50vw",
          margin: "0",
        }}
      >
        {activePage === 1?
        <div>Novel Synthetic Asset AMM Model</div>
        :
        ''
        }
        {activePage === 2?
        <div>Superior Tokenomics & Fair $ISS Launch</div>
        :
        ''
        }
        {activePage === 3?
        <div>Capital Efficiency</div>
        :
        ''
        }
        {activePage === 4?
        <div>Global Access</div>
        :
        ''
        }
        
      </div>
      <div className="row"
        style = {{
          fontFamily: "OpenSansLight",
          letterSpacing: "0rem",
          fontSize: "1.4vw",
          color: "#ffffff",
          paddingTop: "2vh",
          opacity: "1",
          width: "50vw",
          margin: "0",
        }}
      >
        {activePage === 1?
          <div>
            <div>
              Long-/short "Twin asset token model ensures:
            </div>
            <div>
              &#8226; Full protocol funding and solvency at any time, unaffected by price volatility
            </div>
            <div>
              &#8226; LPs in ISSUAA asset pools face no liquidation risk
            </div>
            <div>
              &#8226; LPs require no overcollateralization, maximizing capital efficiency
            </div>
            <div>
              &#8226; the impermanent loss risk of LPs is almost completely mitigable
            </div>
            <div>
              Overall: ISSUAA offers low risk yield farming, akin to stablecoins.

            </div>
          </div>
        :
          ''
        }
        {activePage === 2?
        <div>
          <div>
            &#8226; Value-bearing protocol token linked to the fees generated by the ISSUAA protocol
          </div>
          <div>
            &#8226; Vast majority of $ISS rewarded to the ISSUAA community over time
          </div>
          <div>
            &#8226; State-of-the-art voting escrow veISS staking system
          </div>
          <div>
            &#8226; Best rewards for community members who are in for the long game
          </div>
          <div>
            &#8226; Fully decentralized, on-chain DAO governance
          </div>
        </div>
        :
        ''
        }
        {activePage === 3?
          <div>
            <div>
              &#8226; Every single USD stable coin to be paid in by investors/LPs for minting of assets and liquidity providing in ISSUAA asset pools is “working” and generating returns.
            </div>
            <div>
              &#8226; As no over-collateralization is needed, higher capital efficiency and low risk profile compared to first generation real world assets on chain DeFi protocols.
            </div>
          </div>
        :
          ''
        }
        {activePage === 4?
          <div>
            <div>
              &#8226; Barrier-free access to synthetic assets such as stocks, stock indices, commodities and crypto assets for everybody without the need of a bank account.
            </div>
            <div>
              &#8226; Investing, minting, providing liquidity and trading of synthetic ISSUAA assets directly on-chain.
            </div>            
          </div>
        :
          ''
        }
        
      </div>
    </div>
  );
};

export default Stepper;