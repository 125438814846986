import React, { Component } from 'react';

import MobileSection1 from './mobileSection1';
import MobileSection2 from './mobileSection2';
import MobileSection3 from './mobileSection3';
import MobileSection4 from './mobileSection4';
import MobileSection5 from './mobileSection5';
import MobileSection6 from './mobileSection6';
import MobileFooter from './mobileFooter';

class Mainpage extends Component {
    state = { 
    assetDetails: null,       
    };

    

    render() { 
        return ( 
                
            <div className="p0 m0">
                <MobileSection1 />
                <MobileSection2></MobileSection2>
                <MobileSection3></MobileSection3>
                <MobileSection4></MobileSection4>
                <MobileSection5></MobileSection5>
                <MobileSection6></MobileSection6>
                <MobileFooter />
                    
            </div>
                
             


            
         );
    }
}
 
export default Mainpage;