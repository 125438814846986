import React from "react";
import blockchain from '../img/graphics/Blockchain.png'

const MobileSection4: React.Component<Props> = ({ children }) => {
  return (
    <div className=""
      style = {{
        minHeight:"70vh",
        margin: "0vw",
        backgroundColor: "#0b0b0b",
        paddingLeft: "4vw",
        paddingRight: "4vw",
    }}
    >
      <div className="row w-100 p0 m0"
      style = {{
        fontFamily: "PosteramaRegular",
        letterSpacing: "0.05rem",
        lineHeight: "1.6rem",
        fontSize: "4vw",
        color: "#ffffff",
        paddingTop: "5vh",
        opacity: "1",
        width: "100vh",
        margin: "0",
      }}
      >
        <div className="pl-4 pt-0 pr-4 pb-2 m0">
        <img className="" src={blockchain} alt="Blockchain" width="100%"/>  
        </div>
        <div className="p-0 pt-4 text-left">
          <div className="pb-4">
            Powered by&nbsp; 
            <span
            style = {{
              color:"#fa2aa0",
              textDecoration: "",
            }}
            >Blockchain</span>.
          </div>
          <div className="pb-4">
            Backed by&nbsp; 
            <span
            style = {{
              color:"#1afba9",
              textDecoration: "",
            }}
            >USD</span>&nbsp;stable coins.
          </div>
          <div className="pb-5">
            <span className="pink">$ISS</span> token MULTICHAIN NATIVE VIA <span className="green ">LAYERZERO</span> TECH.
          </div>
          
        </div>
      </div>
      

      
  </div>
  );
};

export default MobileSection4;