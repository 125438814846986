import React from "react";
import rocket from '../img/graphics/Rocket.png'
import Stepper from './Stepper';

const Section3: React.Component<Props> = ({ children }) => {
  return (
    <div className="row"
      style = {{
        minHeight:"85vh",
        margin: "0vw"
    }}
    >
      <div className="image"
        style = {{
          zIndex: "0" ,
        }}
      >
        <div className="layer">
          <div className="row"
            style = {{
              position:"absolute",
              width:"30vw",
              transform: "scaleX(1)",
              webkitTransform: "scaleX(1)",
              marginTop: "22vh",
              marginLeft: "57vw",
              zIndex: "1" ,
            }}
            >
              <img style={{maxHeight: "80vh"}} src={rocket} alt="Rocket"width="100%"/>
            </div>
          </div>
          <div className="row w-100"
            style = {{
              position: "absolute",
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.2rem",
              fontSize: "2.8vw",
              color: "#ffffff",
              paddingTop: "8vh",
              opacity: "1",
              width: "100vh",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              zIndex: "1" ,
            }}
            >
            <div className="col-10 "
            style={{
              paddingLeft: "0vw",
              paddingRight: "0vw",
              lineHeight: "1.2em",
            }}
            >
              ISSUAA OFFERS UNIQUE ADVANTAGES TO  $ISS STAKERS, <span className="green ">DAO MEMBERS</span> and <span className="pink ">liquidity providers</span>
            </div>
          </div>
          <div className="row w-100"
            style = {{
              position: "absolute",
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.2rem",
              fontSize: "2.8vw",
              color: "#ffffff",
              paddingTop: "25vh",
              opacity: "1",
              width: "100vh",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              zIndex: "2" ,
            }}
            >
            <div className="col-10 "
            style={{
              paddingLeft: "0vw",
              paddingRight: "0vw",
            }}
            >
              <Stepper></Stepper>
            </div>
          </div>
          
      </div>

      
  </div>
  );
};

export default Section3;