import React from "react";
import market from '../img/graphics/Market.png'
import coin from '../img/graphics/Coin.png'

const Section2: React.Component<Props> = ({ children }) => {
  return (
    <div className=""
      style = {{
        minHeight:"62vh",
        margin: "0vw",
        backgroundColor: "#0b0b0b",
        paddingLeft: "4vw",
        paddingRight: "4vw",
    }}
    >
      <div className="row w-100"
      style = {{
        fontFamily: "PosteramaRegular",
        letterSpacing: "0.2rem",
        fontSize: "2.8vw",
        color: "#ffffff",
        paddingTop: "5.75vh",
        opacity: "1",
        width: "100vh",
        margin: "0",
      }}
      >
        <div className="col text-center">
          The&nbsp; 
          <span
          style = {{
            color:"#fa2aa0",
            textDecoration: "",
          }}
          >Issuaa</span>
          &nbsp;Protocol
        </div>
      </div>
      <div className="row w-100"
      style = {{
        fontFamily: "OpenSansBold",
        letterSpacing: "0.1rem",
        fontSize: "2vw",
        color: "#ffffff",
        paddingTop: "10vh",
        opacity: "1",
        width: "100vh",
        margin: "0",
      }}
      >
        <div className="col pl-0 pt-4">
          <div className="row">
            <div className="col">
              Synthetic Assets
            </div>
          </div>
          <div className="row"
          style = {{
            fontFamily: "OpenSansLight",
            letterSpacing: "0.0rem",
            fontSize: "1.3vw",
            marginTop: "2vh",
          }}
          >
            <div className="col">
            Empowers you to create, mint, provide liquidity and trade synthethic assets mirroring
             real-world and crypto assets, including stocks, indices, commodities and cryptocurrencies.
            </div>
          </div>
        </div>
        <div className="col-2">
          <img src={market} alt="Market" width="100%"/>
        </div>
        <div className="col">
          <div className="row">
            <div className="col pl-0 pt-4">
              Unique Tokenomics
            </div>
            <div className="row"
          style = {{
            fontFamily: "OpenSansLight",
            letterSpacing: "0.0rem",
            fontSize: "1.3vw",
            marginTop: "2vh",
          }}
          >
            <div className="col">
            ISSUAA offers compelling tokenomics for $ISS stakers, DAO governance participants and liquidity providers, fostering an engaged community.
            </div>
          </div>
          </div>
        </div>
        <div className="col-2">
        <img src={coin} alt="coin" width="100%"/>
        </div>
      </div>

      
  </div>
  );
};

export default Section2;