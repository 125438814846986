import React from "react";


interface Props {
  border: string;
  color: string;
  children?: React.ReactNode;
  height: string;
  onClick: () => void;
  radius: string;
  width: string;
}

const Button: React.Component<Props> = ({
  children,
  onClick,
}) => {
  return (
    <button className="specialButton"
      style={{
        fontFamily: "OpenSansBold",
        backgroundColor: "black",
        border: "solid",
        borderWidth: "3px",
        borderColor: "#fa2aa0",
        opacity: "0.75",
        borderRadius: "20px",
        color: "white",
        paddingTop: "0.6em",
        paddingBottom: "0.6em",
        paddingLeft: "1em",
        paddingRight: "1em",
        marginTop: "1.2em",
        marginBottom: "1em",
        fontSize: "1.4vw",
        fontWeight: "bold",

      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;