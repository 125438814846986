import {Component} from 'react';
import Icon_Github from '../img/icons/Icon_Github.png';
import Icon_Discord from '../img/icons/Icon_Discord.png';
import Icon_Telegram from '../img/icons/Icon_Telegram.png';
import Icon_X from '../img/icons/Icon_X.png';

class MobileNavbar extends Component{


  
  constructor(props) {
    super(props);
    this.state = {  
      account: '',
      loading: true,
    }
  }

  render(){
    return (
        <span>
        <div className="row"
          style ={{
            width:"100%",
            maxHeight: "25vh",
            margin: "0",
            position:"relative",
        }}
        >
            <div className="col col-6"
              style={{
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.4rem",
              fontSize: "9vw",
              fontWeight: "bold",
              color: "#ffffff",
              paddingLeft: "7vw",
              opacity: "1",
              paddingTop: "1.5vh",
              paddingBottom: "1.5vh",
              margin: "0vw",
              zIndex: "1",
            }}>
              ISSUAA
            </div>
            <div className='col'
              style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  fontFamily: "OpenSansRegular",
                  fontSize: "1.7vw",
                  fontWeight: "bold",
                  color: "#ffffff",
                  paddingRight: "5vw",
              }}>
              <a href="https://t.me/issuaa_main" target="_blank"  rel="noreferrer">
                <img src={Icon_Telegram} alt="Telegram" width="20vw"/>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://discord.gg/ttu8vEQM6G" target="_blank"  rel="noreferrer">
                <img src={Icon_Discord} alt="Discord" width="20vw"/>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://github.com/issuaa/issuaa-contracts" target="_blank" rel="noreferrer">
                <img src={Icon_Github} alt="Github" width="20vw"/>
              </a>
              &nbsp;&nbsp;
              <a href="https://twitter.com/issuaaOfficial" target="_blank" rel="noreferrer">
                <img src={Icon_X} alt="X" width="18vw"/>
              </a>
            </div>
            
            
            
        </div>
        </span>
        
    )
  } 
}

export default MobileNavbar;