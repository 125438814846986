import React from "react";
import discordIcon from '../img/discord-icon-white.png'
import globe from '../img/icons/Globe.png'
import bull from '../img/graphics/Bull.png'
import Slide from '@successtar/react-reveal/Slide';
import Button from './Button';
import Navbar from './Navbar';

const Section1: React.Component<Props> = ({ children }) => {
  return (
    <div className="row"
      style = {{
        minHeight:"100vh",
        margin: "0vw"
    }}
    >
      <div className="image">
        
        <div className="layerMobile">
          <Navbar></Navbar>
          <div className="row w-100 p-0 m-0">
            <div className="col-6"
            style = {{
              position:"relative",
              transform: "scaleX(-0.9)",
              webkitTransform: "scaleX(-1)",
              paddingTop: "5vh",
              textAlign: "center",
              
            }}
            >
              <Slide right><img src={bull} alt="Bull" width="85%"/></Slide>
            </div>

            <div className="col-6" 
              style = {{
                position:"relative",
                marginTop: "2vw",
                padding:"0",
                paddingRight:"0vw", 
            }}
          >
              <div style={{
                fontFamily: "PosteramaRegular",
                letterSpacing: "0.4rem",
                fontSize: "3.5vw",
                fontWeight: "bold",
                color: "#fa2aa0",
                paddingTop: "30vh",
                opacity: "1",
              }}>
                ISSUAA
              </div>
              <div style={{
                fontSize: "2vw",
                color: "white",
                paddingRight: "10vw"
              }}
              className = "claimText"
              >
                  Novel DeFi protocol for Derivatives of virtually any Crypto and Real World Asset - on Blockchain
              </div>
              <a href="https://app.issuaa.org" rel="noopener">
                <Button>
                    CHECK OUR WEBAPP
                    &nbsp;
                    <img src={globe} alt="Globe" width="30em"/>
                </Button>
              </a>
              &nbsp;&nbsp;&nbsp;
              
              <a href="https://discord.gg/ttu8vEQM6G" rel="noopener">
                <Button>
                    JOIN OUR DISCORD
                    &nbsp;
                    <img src={discordIcon} alt="Discord" width="30em"/>
                </Button>
              </a>
            </div>
          </div>  
        </div>

      </div>

      
  </div>
  );
};

export default Section1;