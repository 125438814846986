import React from "react";
import rocket from '../img/graphics/Rocket.png'
import MobileStepper from './mobileStepper';

const MobileSection3: React.Component<Props> = ({ children }) => {
  return (
    <div className="row"
      style = {{
        minHeight:"75vh",
        margin: "0vw",
        padding: "0vw",
    }}
    >
      <div className="image">
        <div className="layerMobile">
          <div className=""
            style = {{
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.2rem",
              fontSize: "5vw",
              color: "#ffffff",
              paddingTop: "8vh",
              opacity: "1",
              width: "100%",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              zIndex: "1" ,
            }}
            >
            <div className=""
            style={{
              paddingLeft: "5vw",
              paddingRight: "5vw",
              lineHeight: "1.2em",
              width: "100%",
            }}
            >
              ISSUAA offers unique advantages to $ISS stakers, <span className="green ">DAO members</span> and <span className="pink ">liquidity providers</span>
            </div>
          </div>
          <div className=""
            style = {{
              paddingTop:"5vh",
              width:"100%",
              transform: "scaleX(1)",
              webkitTransform: "scaleX(1)",
              textAlign:"center",
            }}
            >
              <span><img src={rocket} alt="Rocket" width="50%"/></span>
          </div>
          <div className=""
            style = {{
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.2rem",
              fontSize: "2.8vw",
              color: "#ffffff",
              paddingBottom: "10vh",
              opacity: "1",
              width: "100%",
              marginLeft: "2.4vw",
              paddingLeft: "4.6vw",
              paddingRight: "0vw",
            }}
            >
            <div className="row w-100 "
            style={{
              paddingLeft: "5vw",
              paddingRight: "5vw",
            }}
            >
              <MobileStepper></MobileStepper>
            </div>
          </div>
        </div>


          
      </div>

      
  </div>
  );
};

export default MobileSection3;