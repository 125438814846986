import React from "react";
import icon1 from '../img/icons/Icon_1.png'
import icon2 from '../img/icons/Icon_2.png'
import icon3 from '../img/icons/Icon_3.png'
import icon4 from '../img/icons/Icon_4.png'

const Section5: React.Component<Props> = ({ children }) => {
  return (
    <div className="row"
      style = {{
        minHeight:"135vh",
        margin: "0vw"
    }}
    >
      <div className="image"
        style = {{
          zIndex: "0" ,
        }}
      >
        <div className="layer">
          <div className="row w-100"
            style = {{
              position: "absolute",
              fontFamily: "PosteramaRegular",
              letterSpacing: "0.0rem",
              fontSize: "2.95vw",
              color: "#ffffff",
              paddingTop: "8vh",
              opacity: "1",
              width: "100vh",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              zIndex: "1",
            }}
            >
            <div className="col-12 text-center"
            style={{
              paddingLeft: "0vw",
              paddingRight: "0vw",
              lineHeight: "1.2em",
              zIndex:"1",
            }}
            >
              The <span className="pink underlined">ISSUAA</span> protocol token <span className="green">(ISS)</span>
            </div>
            <div className="col-12 text-center"
            style={{
              paddingLeft: "0vw",
              fontSize: "2vw",
              letterSpacing: "0.0rem",
              paddingRight: "0vw",
              lineHeight: "1.2em",
              zIndex:"1",
            }}
            >
              Cash flow linked & value bearing.
            </div>
          </div>
          <div className="row w-100"
            style = {{
              fontFamily: "OpenSansLight",
              letterSpacing: "0.2rem",
              fontSize: "1.2vw",
              color: "#ffffff",
              paddingTop: "25vh",
              opacity: "1",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              paddingBottom: "3vw",
              zIndex: "2" ,
            }}
            >
            <div className="col-6 text-center">
              <div className="w-100"
                style={{
                  lineHeight: "15vh",
                }}>
              <img className="" src={icon2} alt="Blockchain" width="11%"/>
              </div>
              <div className="w-100"
                style = {{
                  fontFamily: "OpenSansBold",
                  letterSpacing: "0rem",
                  fontSize: "1.8vw",
                  color: "#ffffff",
                  paddingTop: "3vh",
                  opacity: "1",
                  width: "100vh",
                  zIndex: "2" ,
                }}
                >Limited supply
              </div>
              <div className="w-100"
                style = {{
                  letterSpacing: "0rem",
                  fontSize: "1.3vw",
                  lineHeight: "3vh",
                  color: "#ffffff",
                  paddingTop: "0.5vh",
                  opacity: "1",
                  width: "100vh",
                  paddingLeft: "0vw",
                  paddingRight: "0vw",
                  zIndex: "2" ,
                }}
              >
              
                The number of ISS tokens that will be issued is limited to a max. supply of 100m.<p />
                Having the max. supply of ISS tokens capped secure that the ISS - as native&nbsp;
                governance token of the ISSUAA protocol and ISSUAA DAO - is non-inflationary in&nbsp;
                the long term.</div>            
            
            </div>
            <div className="col-6 text-center">
              <div className="w-100"
                style={{
                  lineHeight: "15vh",
                }}>
              <img className="" src={icon1} alt="Blockchain" width="15%"/>
              </div>
              <div className="w-100"
                style = {{
                  fontFamily: "OpenSansBold",
                  letterSpacing: "0rem",
                  fontSize: "1.8vw",
                  color: "#ffffff",
                  paddingTop: "3vh",
                  opacity: "1",
                  width: "100vh",
                  zIndex: "2" ,
                }}
                >Value generation by trading fees for the ISS</div>
              <div className="w-100"
                style = {{
                  letterSpacing: "0rem",
                  fontSize: "1.3vw",
                  lineHeight: "3vh",
                  color: "#ffffff",
                  paddingTop: "0.5vh",
                  opacity: "1",
                  width: "100vh",
                  paddingLeft: "0vw",
                  paddingRight: "0vw",
                  zIndex: "2" ,
                }}
              >
              
              Besides the trading fees of transaction volumes of the ISSUAA trading activity which are directly attributed to liquidity providers (0.25%)...<p />
              ... an additional fee (0.05%) is kept for the ISS for every trade on the ISSUAA protocol, which will be used by the protocol to buy back ISS, even deflating the ISS supply over time!
              </div>            
            
            </div>
            <div className="col-6 text-center pt-5">
              <div className="w-100"
                style={{
                  lineHeight: "15vh",
                }}>
                <img className="" src={icon3} alt="Blockchain" width="17%"/>
              </div>
              <div className="w-100"
                style = {{
                  fontFamily: "OpenSansBold",
                  letterSpacing: "0rem",
                  fontSize: "1.8vw",
                  color: "#ffffff",
                  paddingTop: "3vh",
                  opacity: "1",
                  width: "100vh",
                  zIndex: "2" ,
                }}
                >Fair distribution and long-term incentives</div>
              <div className="w-100"
                style = {{
                  letterSpacing: "0rem",
                  fontSize: "1.3vw",
                  lineHeight: "3vh",
                  color: "#ffffff",
                  paddingTop: "0.5vh",
                  opacity: "1",
                  width: "100vh",
                  paddingLeft: "0vw",
                  paddingRight: "0vw",
                  zIndex: "2" ,
                }}
              >
              
              The vast majority of ISS (up to 90%) will be granted to active community members of the ISSUAA protocol. LP rewards will guarantee an attractive yield while adjusting with protocol TVL.<p />
              LPs will get a yield boost depending on their veISS holding.</div>
            </div>
            <div className="col-6 text-center pt-5">
              <div className="w-100"
                style={{
                  lineHeight: "15vh",
                }}>
              <img className="" src={icon4} alt="Blockchain" width="11%"/>
              </div>
              <div className="w-100"
                style = {{
                  fontFamily: "OpenSansBold",
                  letterSpacing: "0rem",
                  fontSize: "1.8vw",
                  color: "#ffffff",
                  paddingTop: "3vh",
                  opacity: "1",
                  width: "100vh",
                  zIndex: "2" ,
                }}
                >
                  veISS token model and rewarded voting
                </div>
              <div className="w-100"
                style = {{
                  letterSpacing: "0rem",
                  fontSize: "1.3vw",
                  lineHeight: "3vh",
                  color: "#ffffff",
                  paddingTop: "0.5vh",
                  opacity: "1",
                  width: "100vh",
                  paddingLeft: "0vw",
                  paddingRight: "0vw",
                  zIndex: "2" ,
                }}
              >
              
              ISS holders can lock (stake) their ISS for up to 4 years and receive veISS in return, the ISSUAA utility and governance token.<p />
              As the locking of ISS and every voting process is rewarded with ISS, owners of the veISS (ISS stakers) are encouraged to vote correctly as they have strong interest in the ISS value.<p />
              One veISS equals one vote in ISSUAA DAO Governance votes.
            </div>
          </div>
          
                    
        </div>


          
      </div>

  </div>
  </div>
  );
};

export default Section5;